import React from 'react';
import {Routes, Route} from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { MultiBackend } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch' 
//import Navbar from './common/Navbar';
import Home from './Home.js';
//import Login from './screens/Login';

function App() {

  const theme = React.useMemo(() => createTheme(themeSettings()), []);

  return (
    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route exact path="/" element={<Home />} />
          </Routes>
        </ThemeProvider>
      </div>
    </DndProvider>
  );
}

export default App;
//export default withAITracking(reactPlugin, App);