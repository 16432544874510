import './Home.css';
import React, { useState, useRef, useEffect } from 'react';
import { Box, Snackbar, Stack, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import LoopingText from './LoopingText';
import { useDrag, useDrop } from 'react-dnd';
import handCursor from './images/hand.png';
import openFridge from './images/open_fridge.png';
import bread from './images/bread.png';
import butter from './images/butter.png';
import dog from './images/dog.png';
import closedFridge from './images/closed_fridge.png';
import butterDog from './images/butter_dog_tp.png';
import butterDogFull from './images/butter_dog.png';
import butterDogVideo from './audio/butterdog.mp4';
import xAudio from './audio/wrong.mp3';  // Add your x audio file
import winAudio from './audio/yay.mp3';  // Add your win audio file
import openDoorAudio from './audio/open-door.mp3';
import foundAudio from './audio/found.mp3';
import telegram from './images/telegram.webp';
import x from './images/x.webp';
import dex from './images/dex.webp';
import pill from './images/pill.webp';

const ca = 'D3gwNP2x9wNbv1YqXbeWg2mgMFNSMXe3no61uKZaEFim';
const pairAddress = '3LFqfU1NCyxvd6VUvPByod2V1NMtuAPRHVyMPFV8vrAH';
const teleLink = `https://t.me/butter_dog_sol`;
const xLink = "https://twitter.com/butter_dog_sol";
const dexLink = `https://dexscreener.com/solana/${pairAddress}`;
//const pumpfunLink = `https://pump.fun/${ca}`;

const ItemTypes = {
  BUTTER: 'butter',
};

function DraggableButter() {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.BUTTER,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <img
      ref={drag}
      id="butter"
      src={butter}
      alt="butter"
      style={{
        width: '10vw',
        height: 'auto',
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    />
  );
}

function DroppableArea({ id, onDrop, children }) {
  const [, drop] = useDrop(() => ({
    accept: ItemTypes.BUTTER,
    drop: (item, monitor) => onDrop(id, monitor),
  }));

  return (
    <div ref={drop} id={id} style={{ width: '20vw', height: 'auto' }}>
      {children}
    </div>
  );
}

const HoverImage = styled('img')({
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.2)',
  },
  cursor: "pointer"
});

function ClipboardComponent() {
  const [open, setOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(ca)
      .then(() => {
        setOpen(true);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        onClick={handleCopy}
        sx={{ cursor: 'pointer', border: '3px solid white', px: 2, pb: 0.5, borderRadius: 2 }}
      >
        <Typography fontSize="5vh">
          ca: {ca}
        </Typography>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message="Copied to clipboard"
      />
    </Box>
  );
}

function Home() {
  const interval = 1000; // Change text every 1 second
  const typingSpeed = 70; // Time between each character (0.1 seconds)
  const mobile = useMediaQuery('(max-width:800px)');
  const texts = ['butter dog', 'dog wif da butter on him', 'butter dog']
  const [clicked, setClicked] = useState(false);
  const [butterClicked, setButterClicked] = useState(false);
  const [fridgeLoaded, setFridgeLoaded] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [showButterDog, setShowButterDog] = useState(false);
  const [showButterDogFull, setShowButterDogFull] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const [showHello, setShowHello] = useState(false);
  const [manualPlay, setManualPlay] = useState(false);
  const xAudioRef = useRef(new Audio(xAudio));
  const winAudioRef = useRef(new Audio(winAudio));
  const openDoorAudioRef = useRef(new Audio(openDoorAudio)); // Create an audio element
  const foundAudioRef = useRef(new Audio(foundAudio)); // Create an audio element

  const handleFridgeClick = () => {
    openDoorAudioRef.current.play(); // Play the audio
    setClicked(true);
  };

  const handleButterClick = () => {
    foundAudioRef.current.play(); // Play the audio
    setButterClicked(true);
  };

  const handleDogClick = () => {
    winAudioRef.current.play(); // Play the audio
    setCompleted(true);
  };

  const handleBreadClick = () => {
    xAudioRef.current.play(); // Play the audio
  };

  useEffect(() => {
    if (completed) {
      setTimeout(() => {
        setShowButterDog(true);
        setTimeout(() => {
          setShowButterDogFull(true);
          let opacityInterval = setInterval(() => {
            setOpacity((prev) => {
              if (prev >= 1) {
                clearInterval(opacityInterval);
                return 1;
              }
              return prev + 0.1;
            });
          }, 200);
          setTimeout(() => {
            setShowVideo(true);
          }, 2200);
        }, 1000);
      }, 2000);
    }
  }, [completed]);

  const handleDrop = (targetId) => {
    if (targetId === 'bread') {
      xAudioRef.current.play();
    } else if (targetId === 'dog') {
      winAudioRef.current.play();
      setCompleted(true);
    }
  };

  const handleVideoEnded = () => {
    setShowHello(true);
  };

    const handleVideoEndedMobile = () => {
        //wait 8 seconds then show the hello message
        setTimeout(() => {
            setShowHello(true);
        }, 8000);
    }

  return (
    <Stack alignItems="center" spacing={1} p={2} pb={8}>
      {showHello ? (
        <Stack alignItems="center" spacing={1} p={2}>
          <Stack direction="row" alignItems="center" spacing={2} pb={5}>
            <HoverImage src={telegram} alt={"telegram"} width="50px" height="auto" onClick={() => window.open(teleLink, "_blank")} />
            <HoverImage src={x} alt={"x"} width="38px" height="auto" onClick={() => window.open(xLink, "_blank")} />
            <HoverImage src={dex} alt={"dexscreener"} width="40px" height="auto" onClick={() => window.open(dexLink, "_blank")} />
          </Stack>
          <img src={butterDogFull} alt="butterdog" style={{ width: mobile ? '55vw' : '30vw', height: 'auto' }} />
          <LoopingText texts={texts} interval={interval} typingSpeed={typingSpeed} />
          <Box height="8vh" />
          <ClipboardComponent />
        </Stack>
      ) : showVideo ? (
        mobile ? <Stack>
            <video width={mobile ? "80%" : "50%"} height="auto" controls onPlay={handleVideoEndedMobile}>
            <source src={butterDogVideo} type="video/mp4" />
            Your browser does not support the video tag.
            </video>
            </Stack> : <video width={mobile ? "80%" : "50%"} height="auto" autoPlay onEnded={handleVideoEnded}>
          <source src={butterDogVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : showButterDogFull ? (
        <div style={{ position: 'relative' }}>
          <img src={butterDog} alt="butterdog" style={{ width: mobile ? '80vw' : '50vw', height: 'auto' }} />
          <img
            src={butterDogFull}
            alt="butterdogfull"
            style={{ width: mobile ? '80vw' : '50vw', height: 'auto', position: 'absolute', top: 0, left: 0, opacity }}
          />
        </div>
      ) : showButterDog ? (
        <img src={butterDog} alt="butterdog" style={{ width: mobile ? '80vw' : '50vw', height: 'auto' }} />
      ) : (
        <Stack alignItems="center" spacing={1} p={2} pb={10}>
          {clicked ? (
            <Stack spacing={2} alignItems="center" p={5} pt={mobile ? '1vh' : '3vh'}>
              {!butterClicked ? (
                <>
                  <Stack alignItems="center" style={{ position: 'relative', width: mobile ? '90%' : '45%', height: 'auto' }}>
                    <img
                      className="fridge"
                      src={openFridge}
                      alt="fridge"
                      style={{ width: mobile ? '74vw' : '39.5vw', height: 'auto' }}
                      onLoad={() => setFridgeLoaded(true)}
                    />
                    <img
                      className="butter"
                      src={butter}
                      alt="butter"
                      style={{
                        position: 'absolute',
                        top: '31%',
                        left: mobile ? '67%' : '70%',
                        transform: 'translate(-50%, -50%) rotate(20deg)',
                        width: mobile ? '20%' : '32%', // Adjust size as needed
                        height: 'auto',
                        cursor: `url(${handCursor}), auto`
                      }}
                      onClick={handleButterClick}
                    />
                  </Stack>
                  {fridgeLoaded && (
                    <Typography fontSize={mobile ? "4vh" : "5vh"} fontWeight="bold" fontFamily='"Comic Neue", "Comic Sans MS", "Comic Sans"' sx={{ pb: 2 }} textAlign="center">
                      Hint: find what you need
                    </Typography>
                  )}
                </>
              ) : (
                <Stack spacing={'5vh'} pt={'10vh'}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={mobile ? '8vw' : '10vw'}
                    width="100%"
                  >
                    <DroppableArea id="bread" onDrop={handleDrop}>
                      <img src={bread} alt="bread" style={{ width: mobile ? '30vw' : '20vw', height: 'auto', paddingRight: mobile ? 50 : 0 }} onClick={()=>{if (mobile) handleBreadClick();}}/>
                    </DroppableArea>
                    <DraggableButter />
                    <DroppableArea id="dog" onDrop={handleDrop}>
                      <img src={dog} alt="dog" style={{ width:  mobile ? '34vw' : '20vw', height: 'auto' }}  onClick={()=>{if (mobile) handleDogClick();}}/>
                    </DroppableArea>
                  </Stack>
                  {mobile ? 
                  <Typography fontSize="4vh" fontWeight="bold" fontFamily='"Comic Neue", "Comic Sans MS", "Comic Sans"' sx={{ pb: 2 }} textAlign="center">
                    Hint: click the host you'd like to place your butter on
                  </Typography> : <Typography fontSize="5vh" fontWeight="bold" fontFamily='"Comic Neue", "Comic Sans MS", "Comic Sans"' sx={{ pb: 2 }} textAlign="center">
                    Hint: drag the butter to your desired host
                  </Typography> 
                  }
                </Stack>
              )}
            </Stack>
          ) : (
            <Stack spacing={2} alignItems="center" p={5} pt={mobile ? '2vh' : '4vh'}>
              <img
                src={closedFridge}
                alt="fridge"
                width={mobile ? "400px" : "60%"}
                height="auto"
                style={{ cursor: `url(${handCursor}), auto` }}
                onClick={handleFridgeClick}
              />
              {mobile ? 
                  <Typography fontSize="4vh" fontWeight="bold" fontFamily='"Comic Neue", "Comic Sans MS", "Comic Sans"' sx={{ pb: 2 }} textAlign="center">
                    Hint: open the fridge
                  </Typography> : <Typography fontSize="5vh" fontWeight="bold" fontFamily='"Comic Neue", "Comic Sans MS", "Comic Sans"' sx={{ pb: 2 }} textAlign="center">
                  Hint: open the fridge
                  </Typography> 
                }   
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default Home;
