import React, { useState, useEffect, useRef } from 'react';
import { Typography, useMediaQuery } from '@mui/material';

const LoopingText = ({ texts, textInterval, typingSpeed }) => {
    const mobile = useMediaQuery('(max-width:600px)');
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [currentCharIndex, setCurrentCharIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState('');
    const [isPausing, setIsPausing] = useState(false);
    const [maxHeight, setMaxHeight] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        // Calculate the maximum height based on all texts
        if (containerRef.current) {
            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.visibility = 'hidden';
            tempDiv.style.height = 'auto';
            document.body.appendChild(tempDiv);

            let maxHeight = 0;
            texts.forEach((text) => {
                tempDiv.innerText = text;
                maxHeight = Math.max(maxHeight, tempDiv.clientHeight);
            });

            setMaxHeight(maxHeight);
            document.body.removeChild(tempDiv);
        }
    }, [texts]);

    useEffect(() => {
        if (isPausing) {
            const pauseTimeout = setTimeout(() => {
                setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
                setCurrentCharIndex(0);
                setDisplayedText('');
                setIsPausing(false);
            }, 1000);

            return () => clearTimeout(pauseTimeout);
        }

        if (currentCharIndex < texts[currentTextIndex].length) {
            const typingTimeout = setTimeout(() => {
                setDisplayedText((prev) => prev + texts[currentTextIndex][currentCharIndex]);
                setCurrentCharIndex(currentCharIndex + 1);
            }, typingSpeed);

            return () => clearTimeout(typingTimeout);
        } else {
            const nextTextTimeout = setTimeout(() => {
                setIsPausing(true);
            }, textInterval);

            return () => clearTimeout(nextTextTimeout);
        }
    }, [currentCharIndex, texts, currentTextIndex, typingSpeed, textInterval, isPausing]);

    return (
        <div style={{ height: maxHeight }} ref={containerRef}>
            <Typography fontSize={mobile ? 22: "2.5vw"} fontWeeight="bold" fontFamily='"Comic Sans MS", "Comic Sans", "Comic Neue"' >{displayedText}</Typography>
        </div>
    );
};

export default LoopingText;
